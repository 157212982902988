import { getAuth, onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Header() {
	const [pageState, setPageState] = useState("Sign In")
	const location = useLocation();
    const navigate = useNavigate();
	const auth = getAuth();

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				setPageState('Profile')
			} else {
				setPageState('Sign In')
			}
		})
	})

    function pathMatchRoute(route) {
        if (route === location.pathname) {
            return true
        }
    }

	return (
		<header className="HeaderContainer">
			<img src="Logo-Black.png" alt="Logo" className="HeaderImage" onClick={ () => navigate("/")} />
			<ul>
				<li className={`HeaderNavigation ${(pathMatchRoute("/")) && "SelectedHeaderNavigation"}`} onClick={ () => navigate("/")}>Home</li>
				<li className={`HeaderNavigation ${(pathMatchRoute("/gallery")) && "SelectedHeaderNavigation"}`} onClick={ () => navigate("/gallery")}>Gallery</li>
				<li className={`HeaderNavigation ${(pathMatchRoute("/sign-in") || pathMatchRoute("/profile") || pathMatchRoute("/sign-up") || pathMatchRoute("/forgot-password")) && "SelectedHeaderNavigation"}`} onClick={ () => navigate("/profile")}>{pageState}</li>
			</ul>
		</header>
	);
}
