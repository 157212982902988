import React from "react";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import { json } from "./BetaSurvey";
import { db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { getAuth } from "firebase/auth";


StylesManager.applyTheme("defaultV2");

const SurveyComponent = ({showSurveyStateChanger, ...rest}) => {
	const survey = new Model(json);
	survey.showProgressBar = "bottom";
    const auth = getAuth();

	async function onSurveyComplete(survey) {
        const json = survey.data;

        try {
            await setDoc(doc(db, "betaSurvey", auth.currentUser.uid), json);
            toast.success("Thank you! Your response has been recorded.");
		} catch (err) {
            // Error
            toast.error("There was an error saving your submission.");
		}
        
        await timeout(3000); // wait for 3 sec delay

        showSurveyStateChanger(false)
	}

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

	return (
		<Survey
			model={survey}
			onComplete={(survey) => {
				onSurveyComplete(survey);
			}}
		/>
	);
}

export default SurveyComponent;
