import { getAuth, updateProfile } from "firebase/auth";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../firebase";
import SurveyComponent from "../surveys/BetaSurveyComponent";
import "../css/Survey.css";

export default function Profile() {
	const auth = getAuth();
	const navigate = useNavigate();
	const [changeDetail, setChangeDetail] = useState(false);
	const [formData, setFormData] = useState({ name: auth.currentUser.displayName, email: auth.currentUser.email });
	const { name, email } = formData;
	const [showSurvey, setShowSurvey] = useState(false);
	const [canShowSurvey, setCanShowSurvey] = useState(false);
	const myContainer = useRef(null);

	function onSignOut() {
		auth.signOut();
		navigate("/sign-in");
	}

	function onChange(e) {
		setFormData((prevState) => ({
			...prevState,
			[e.target.id]: e.target.value,
		}));
	}

	useEffect(() => {
		if (changeDetail) {
			myContainer.current.focus();
		}
		checkIfCanShowSurvey();
	});

	async function onSubmit() {
		try {
			if (auth.currentUser.displayName !== name && name.length > 0) {
				await updateProfile(auth.currentUser, { displayName: name });
				const docRef = doc(db, "users", auth.currentUser.uid);
				await updateDoc(docRef, { name: name });
				toast.success("Success! Profile details have been updated.");
			} else {
				setFormData((prevState) => ({
					...prevState,
					name: auth.currentUser.displayName,
				}));
			}
		} catch (error) {
			toast.error("Failed to update the profile details.");
		}
	}

	async function checkIfCanShowSurvey() {
		await timeout(500); // wait for 0.25 sec delay

		const docRef = doc(db, "betaSurvey", auth.currentUser.uid);

		try {
			const taskSnap = await getDoc(docRef);
			if (taskSnap.data()) {
				setCanShowSurvey(false);
			} else {
				setCanShowSurvey(true);
			}
		} catch (error) {
			setCanShowSurvey(true);
		}
	}

	function timeout(delay) {
		return new Promise((res) => setTimeout(res, delay));
	}

	function showBetaSurvey() {
		setShowSurvey(true);
	}

	return (
		<div className="PageSection">
			<h1 className="PageHeader">My Profile</h1>
			<div className="ProfileContainer">
				<form className="RegisterForm">
					{/* Name Input */}
					<input type="text" id="name" ref={myContainer} value={name} className={`ProfileInputDisabled ${changeDetail && "ProfileInput"}`} onChange={onChange} />
					{/* Email Input */}
					<input type="email" id="email" value={email} className="ProfileInputDisabled" />
					<div className="RegistrationTextContainer">
						<p>
							Change your name?
							<span
								className="RegistrationTextButton"
								onClick={() => {
									changeDetail && onSubmit();
									setChangeDetail(!changeDetail);
								}}
							>
								{changeDetail ? "Apply Change" : "Edit"}
							</span>
						</p>
						<p className="SignOutPasswordButton" onClick={onSignOut}>
							Sign Out
						</p>
					</div>
				</form>
				{canShowSurvey ? (
					<button className="SurveyButton" onClick={showBetaSurvey}>
						Take our survey
					</button>
				) : null}
				<div className={`BetaSurvey ${!showSurvey && "BetaSurveyHidden"}`}>{canShowSurvey ? <SurveyComponent showSurveyStateChanger={setShowSurvey} /> : null}</div>
			</div>
		</div>
	);
}
