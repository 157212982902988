import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./css/App.css";
import ParticleEffect from "./js/Particles";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./js/Home";
import Header from "./components/Header";
import Profile from "./js/Profile";
import SignIn from "./js/SignIn";
import SignUp from "./js/SignUp";
import ForgotPassword from "./js/ForgotPassword";
import TermsOfService from "./js/TermsOfService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Gallery from "./js/Gallery";

function App() {
	return (
		<div className="App">
			<Router>
				<ParticleEffect className="Particles" />

				<div className="AppContainer">
					<Header />

					<Routes>
						
						<Route path="/" element={<Home />} />

						<Route path="/profile" element={<PrivateRoute />}>
							<Route path="/profile" element={<Profile />} />
						</Route>

						<Route path="/gallery" element={<Gallery />} />
						
						<Route path="/sign-in" element={<SignIn />} />
						<Route path="/terms-of-service" element={<TermsOfService />} />
						<Route path="/sign-up" element={<SignUp />} />
						<Route path="/forgot-password" element={<ForgotPassword />} />
					</Routes>

				</div>
			</Router>
			<ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
		</div>
	);
}

export default App;
