import React, { useState, useEffect } from "react";
import "../css/App.css";

function Home() {
	const [alpha, setAlpha] = useState(0.0);
	const [currentId, setCurrentId] = useState(0);

	useEffect(() => {		

		async function animateAlpha() {
			var i = 0;

			while (i < 1) {
				i += 0.05;
				setAlpha(i)
				await timeout(50)
			}

			await timeout(3000)
	
			while (i > 0) {
				i -= 0.05;
				setAlpha(i)
				await timeout(50)
			}
			await timeout(350)
		}
	
		function timeout(delay) {
			return new Promise((res) => setTimeout(res, delay));
		}
		
		async function animateIds() {
			while (true) {
				for (var n = 1; n <= 6; n++) {
					setCurrentId(n);
					await timeout(50)
					await animateAlpha();
				}
			}
		}

		animateIds();

	}, []);

	function getAlpha(id) {
		if (id === currentId) {
			return alpha;
		}
		return 0;
	}

	return (
		<div className="PageSection">
			<h1 className="HomeHeader">HÚki Labs</h1>
			<h4 className="HomeSubheader">Defining Reality</h4>
			<div className="HomeRowContainer">
				<div className="HomeColumnContainer">
					<div className="HomeDescription" style={{opacity: getAlpha(6)}}>
						<div>WHEN?</div><div className="HomeDescriptionBody"><br></br>Soon™️</div>
					</div>
					<div className="HomeDescription" style={{opacity: getAlpha(4)}}>
					<div>WHAT?</div><div className="HomeDescriptionBody"><br></br>We are HÚKI.<br></br>Technology is our language.<br></br>The world is our canvas.</div>
					</div>
					<div className="HomeDescription" style={{opacity: getAlpha(2)}}>
						No borders, no rules.<br></br>Just people making cool sh*t, together.
					</div>
				</div>
				<div className="HomeColumnContainer">
					<div className="HomeDescription" style={{opacity: getAlpha(3)}}>
					<div>WHO?</div><div className="HomeDescriptionBody"><br></br>A couple of kids with a dream.</div>
					</div>
					<div className="HomeDescription" style={{opacity: getAlpha(1)}}>
					DEFINING WEB3 THROUGH <br></br> KICK-A** EXPERIENCES.
					</div>
					<div className="HomeDescription" style={{opacity: getAlpha(5)}}>
					<div>WHERE?</div><div className="HomeDescriptionBody"><br></br>ALL REALITIES,<br></br>...INSIDE THE METAVERSE<br></br></div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Home;
