import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default function ForgotPassword() {
	const [email, setEmail] = useState("");

	function onChange(e) {
		setEmail(e.target.value);
	}

	async function onSubmit(e) {
		e.preventDefault();

		try {
			const auth = getAuth();
			await sendPasswordResetEmail(auth, email);

			toast.success("Success! Follow the instructions sent to your email.");
		} catch (error) {
			switch (true) {
				case error.message === "Firebase: Error (auth/missing-email).":
					toast.error("Invalid email address.");
					break;
				case error.message === "Firebase: Error (auth/user-not-found).":
					toast.error("We can't find your account. Maybe try registering instead?");
					break;
				default:
					toast.error("Failed to send 'reset password' email.");
					break;
			}
		}
	}

	return (
		<section className="PageSection">
			<h1 className="PageHeader">Forgot Password</h1>
					<form onSubmit={onSubmit} className="RegisterForm">
						<input
							className="RegistrationInput"
							type="email"
							id="email"
							value={email}
							onChange={onChange}
							placeholder="Email address"
						></input>
						<div className="RegistrationTextContainer">
							<p className="mb-6">
								Don't have an account?
								<Link to="/sign-up" className="RegistrationTextButton">
									Register
								</Link>
							</p>
							<p>
								<Link to="/sign-in" className="RegistrationTextButton">
									Sign in instead
								</Link>
							</p>
						</div>
						<button
							type="submit"
							className="RegisterButton"
						>
							Send Reset Password
						</button>
					</form>
		</section>
	);
}
