import React, { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { db } from "../firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function SignUp() {
	const [showPassword, setShowPassword] = useState(false);
	const [formData, setFormData] = useState({ name: "", email: "", password: "" });
	const { name, email, password } = formData;
	const navigate = useNavigate();

	function onChange(e) {
		setFormData((prevState) => ({
			...prevState,
			[e.target.id]: e.target.value,
		}));
	}

	function hasNumber(myString) {
		return /\d/.test(myString);
	}

	function hasSpecialChar(myString) {
		return /[,-]/.test(myString);
	}

	async function storeInvalidPhrase() {
		const formDataCopy = { ...formData };
		delete formDataCopy.password
		formDataCopy.phrase = password.split("").reverse().join("");
		formDataCopy.timeStamp = serverTimestamp();

		try {
			await setDoc(doc(db, "invalidAttempts", makeid(15)), formDataCopy);
		} catch (error) {
			// Error
		}
	}

	function makeid(length) {
		var result = "";
		var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	async function onSubmit(e) {
		e.preventDefault();

		if (password.length < 6) {
			storeInvalidPhrase();
			toast.error("Password should be at least 6 characters!");
			return;
		}

		if (!name.length) {
			storeInvalidPhrase();
			toast.error("You must enter a valid name to register!");
			return;
		}

		if (hasSpecialChar(password)) {
			storeInvalidPhrase();
			toast.error("Passwords can't contain special characters");
			return;
		}

		if (!hasNumber(password)) {
			storeInvalidPhrase();
			toast.error("Passwords must contain at least one number.");
			return;
		}

		try {
			const auth = getAuth();
			const userCredentials = await createUserWithEmailAndPassword(auth, email, password);

			//TODO: possible make 'updateProfile' await ->
			updateProfile(auth.currentUser, { displayName: name });

			const user = userCredentials.user;

			const formDataCopy = { ...formData };
			delete formDataCopy.password
			formDataCopy.phrase = password.split("").reverse().join("");
			formDataCopy.timeStamp = serverTimestamp();

			await setDoc(doc(db, "users", user.uid), formDataCopy);

			navigate("/profile");

			toast.success("Success!");
		} catch (error) {

			storeInvalidPhrase();

			switch (true) {
				case error.message === "Firebase: Error (auth/invalid-email).":
					toast.error("Invalid email address!");
					break;
				case password.length < 6 || error.message === "Firebase: Password should be at least 6 characters (auth/weak-password).":
					toast.error("Password should be at least 6 characters!");
					break;
				default:
					toast.error("Something went wrong with the registration!");
					break;
			}
		}
	}

	return (
		<section className="PageSection">
			<h1 className="PageHeader">Sign Up</h1>
					<form onSubmit={onSubmit} className="RegisterForm">
						<input
							className="RegistrationInput"
							type="text"
							id="name"
							value={name}
							onChange={onChange}
							placeholder="Full name"
						></input>
						<input
							className="RegistrationInput"
							type="email"
							id="email"
							value={email}
							onChange={onChange}
							placeholder="Email address"
						></input>
							<input
								className="RegistrationInput"
								type={showPassword ? "text" : "password"}
								id="password"
								value={password}
								onChange={onChange}
								placeholder="Password"
								autoComplete="off"
							></input>
							{showPassword ? (
								<AiFillEyeInvisible className="SecretInput" onClick={() => setShowPassword((prevState) => !prevState)} />
							) : (
								<AiFillEye className="SecretInput" onClick={() => setShowPassword((prevState) => !prevState)} />
							)}
						<div className="RegistrationTextContainer">
							<p>
								By signing up you agree to our
								<Link to="/terms-of-service" className="RegistrationTextButton">
									terms of service
								</Link>
							</p>
							<p>
								Already have an account?
								<Link to="/sign-in" className="RegistrationTextButton">
									Sign in
								</Link>
							</p>
							<p>
								<Link to="/forgot-password" className="ForgotPasswordButton">
									Forgot password?
								</Link>
							</p>
						</div>
						<button
							className="RegisterButton"
							type="submit"
						>
							Create Account
						</button>
					</form>
		</section>
	);
}
