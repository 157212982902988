import React, { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { toast } from "react-toastify";

export default function SignIn() {
	const [showPassword, setShowPassword] = useState(false);
	const [formData, setFormData] = useState({ email: "", password: "" });
	const { email, password } = formData;

	const navigate = useNavigate();

	function onChange(e) {
		setFormData((prevState) => ({
			...prevState,
			[e.target.id]: e.target.value,
		}));
	}

	async function onSubmit(e) {
		e.preventDefault();

		try {
			const auth = getAuth();
			const userCredentials = await signInWithEmailAndPassword(auth, email, password);
			if (userCredentials.user) {
				navigate("/profile");
				toast.success("Success! You are now signed in.");
			}
		} catch (error) {
			console.log(error.message);
			switch (true) {
				case error.message === "Firebase: Error (auth/user-not-found).":
					toast.error("We can't find your account. Maybe try registering instead?");
					break;
				case password.length < 6:
					toast.error("Password must be at least 6 characters.");
					break;
				case error.message ===
					"Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).":
					toast.error("Account temporarily disabled. Please try again later.");
					break;
				case error.message === "Firebase: Error (auth/invalid-email).":
					toast.error("Please enter a valid email.");
					break;
				default:
					toast.error("Unable to sign in.");
					break;
			}
		}
	}

	return (
		<section className="PageSection">
			<h1 className="PageHeader">Sign In</h1>
			<form onSubmit={onSubmit} className="RegisterForm">
				<input className="RegistrationInput" type="email" id="email" value={email} onChange={onChange} placeholder="Email address"></input>
				<input className="RegistrationInput" type={showPassword ? "text" : "password"} id="password" value={password} onChange={onChange} placeholder="Password"></input>
				{showPassword ? (
					<AiFillEyeInvisible className="SecretInput" onClick={() => setShowPassword((prevState) => !prevState)} />
				) : (
					<AiFillEye className="SecretInput" onClick={() => setShowPassword((prevState) => !prevState)} />
				)}
				<div className="RegistrationTextContainer">
					<p>
						Don't have an account?
						<Link to="/sign-up" className="RegistrationTextButton">
							Register
						</Link>
					</p>
					<p>
						<Link to="/forgot-password" className="ForgotPasswordButton">
							Forgot password?
						</Link>
					</p>
				</div>
				<button
					type="submit"
					className="RegisterButton"
				>
					Sign In
				</button>
			</form>
		</section>
	);
}
