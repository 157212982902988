import React from "react";
import "../css/App.css";

function Gallery() {
	return (
		<div className="PageSection">
			<video src="Gallery-Background.mp4" type="video/mp4" muted="muted" autoPlay loop playsInline className="GalleryBackgroundVideo">
			</video>
			{/* <div className="GalleryBackgroundVideo BlurredBackground"></div> */}
			<h1 className="PageHeader TopView">Our Works</h1>
			<h2 className="GalleryInfo TopView">Coming soon...</h2>
			<div className="DemoContainer TopView">
				<div></div>
				<a className="DemoButtonContainer" href="https://brcc-fit-fuel.continuum-xr.com">
					<button className="SurveyButton TopView">Try our AR Demo</button>
				</a>
			</div>
		</div>
	);
}

export default Gallery;
