export const json = {
    title: "Huki Labs Beta Survey",
    pages: [
      {
        "title": "The Metaverse",
        "elements": [
          {
            "type": "text",
            "name": "DescribeTheMetaverse",
            "title": "How would you describe the metaverse? (1-2 sentences)",
            "isRequired": true,
          }
        ]
      },
      {
        "title": "Augmented VS. Virtual Reality",
        "elements": [
          {
            "type": "radiogroup",
            "name": "KnowARvVRDifference",
            "title": "Do you know the difference between AR and VR?",
            "isRequired": true,
            "choices": [
              "Yes",
              "No"
            ]
          }
        ]
      },
      {
        "title": "Augmented VS. Virtual Reality",
        "elements": [
          {
            "type": "radiogroup",
            "name": "EverUsedARBefore",
            "title": "Have you ever used AR (Augmented Reality) before?",
            "isRequired": true,
            "choices": [
              "Yes",
              "No"
            ]
          },
          {
            "type": "checkbox",
            "name": "UsedARDevices",
            "title": "What devices have you experienced AR on?",
            "visibleIf": "{EverUsedARBefore}='Yes'",
            "isRequired": true,
            "choices": [
              "Mobile Device",
              "Computer",
              "AR Headset",
              "Other"
            ]
          }
        ]
      },
      {
        "title": "Augmented VS. Virtual Reality",
        "elements": [
          {
            "type": "radiogroup",
            "name": "EverUsedVRBefore",
            "title": "Have you ever used VR (Virtual Reality) before?",
            "isRequired": true,
            "choices": [
              "Yes",
              "No"
            ]
          },
          {
            "type": "checkbox",
            "name": "UsedVRDevices",
            "title": "What devices have you experienced VR on?",
            "visibleIf": "{EverUsedVRBefore}='Yes'",
            "isRequired": true,
            "choices": [
              "Mobile Device",
              "Computer",
              "VR Headset",
              "Other"
            ]
          }
        ]
      },
      {
        "title": "AR Engagement",
        "elements": [
          {
            "type": "radiogroup",
            "name": "ARBeatsTraditionalAds",
            "title": "Do you think AR advertising is more engaging than traditional advertising?",
            "isRequired": true,
            "choices": [
              "Yes",
              "No"
            ]
          },
          {
            "type": "rating",
            "name": "AREngagementRating",
            "title": "How engaging do you think AR advertising is?",
            "isRequired": true,
            "visibleIf": "{ARBeatsTraditionalAds}='Yes'",
            "rateMin": 0,
            "rateMax": 5,
            "minRateDescription": "(Not Engaging)",
            "maxRateDescription": "(Highly Engaging)"
          },
          {
            "type": "text",
            "name": "AREngagementDescription",
            "title": "Why do you think AR advertising is less engaging?",
            "isRequired": true,
            "visibleIf": "{ARBeatsTraditionalAds}='No'",
          }
        ]
      }, {
        "title": "Referrals",
        "elements": [
          {
            "type": "rating",
            "name": "ReferralRating",
            "title": "How likely are you to recommend AR/VR products to a friend?",
            "isRequired": true,
            "rateMin": 0,
            "rateMax": 5,
            "minRateDescription": "(Most unlikely)",
            "maxRateDescription": "(Most likely)"
          }
        ]
      },
      {
        "title": "Referrals",
        "elements": [
          {
            "type": "text",
            "name": "ReferralDescription",
            "title": "Please explain why (1-2 sentences)",
            "isRequired": false,
          }
        ]
      },
    ]
  };